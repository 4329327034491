import React from 'react'
import '../SinglePage.scss'
import Text from './Text'

// FUNCTIONAL COMPONENT
const Imprint = () => {
    return (
        <div className="single-page">
            <Text/>
        </div>
    )
}

export default Imprint